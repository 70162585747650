<template>
    <div class="div-principal">
        <v-row>
            <!-- Primeiro Card -->
            <v-col cols="12" sm="6" md="4">
                <v-card elevation="16" class="card-principal">
                    <v-card-title>
                        <div style="color: black; background-color: #E0E0E0; word-wrap: break-word; white-space: normal; border-radius: 5px; text-align: center; font-size: 20px;">
                            Dia 20/12/2024, horario 10:26:50, Tempo de uso 50:53:10
                        </div>
                    </v-card-title>

                    <v-card-title style="text-align: center; font-size: 25px;">Rodrigo</v-card-title>
                    <div class="card-body" @click="openModalApontamentoAbertoBody"
                        style="display: flex; flex-direction: row; align-items: center;">
                         <div style="flex: 1; display: flex; justify-content: center; padding: 8px;">
                            <v-img  src="https://cdn-icons-png.flaticon.com/512/4427/4427599.png" 
                                    alt="imagem operado"
                                    class="card-img"
                                    style="max-height: 120px; max-width: 100%;"
                            ></v-img>
                         </div>

                        <div style="flex: 2; display: flex; flex-direction: column; gap: 8px;">
                            <v-card
                            style="background-color: #E0E0E0; color: black;"
                            elevation="5"
                            >
                            <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                <v-icon
                                class="mdi mdi-account-outline"
                                style="margin-right: 8px;"
                                ></v-icon>
                                <small style="color: black !important;font-size: 16px;font-weight: 780;">Operador: </small> <small style="font-size: 17px; font-weight: 500;">(exemplo: OP001)</small>
                               
                            </v-card-title>
                            </v-card>
                            <v-card
                            style="background-color: #E0E0E0; color: black;"
                            elevation="5"
                            >
                            <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                <v-icon
                                class="mdi mdi-cog-outline"
                                style="margin-right: 8px;"
                                ></v-icon>
                                <small style="color: black !important; font-size: 16px; font-weight: 780;">Torno: </small> <small style="font-size: 17px; font-weight: 500;">(exemplo: torno romi)</small>
                                
                            </v-card-title>
                            </v-card>
                            <v-card
                            style="background-color: #E0E0E0; color: black;"
                            elevation="5"
                            >
                            <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                <v-icon
                                class="mdi mdi-arrow-left-right-bold-outline"
                                style="margin-right: 8px;"
                                ></v-icon>
                                <small style="color: black !important; font-size: 16px; font-weight: 780;">Fuso Avanço Rebolo: </small> <small style="font-size: 17px; font-weight: 500;">(IT00227)</small>
                            </v-card-title>
                            </v-card>
                            <v-card
                            style="background-color: #E0E0E0;"
                            elevation="5"
                            >
                            <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                <v-icon
                                class="mdi mdi-calendar-range"
                                style="margin-right: 8px;"
                                ></v-icon>
                                <small style="color: black !important; font-size: 16px; font-weight: 780;">Data: </small> <small style="font-size: 17px; font-weight: 500;">(20/12/2024)</small>
                                
                            </v-card-title>
                            </v-card>
                            <v-card
                            style="background-color: #E0E0E0;"
                            elevation="5"
                            >
                            <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                <v-icon
                                class="mdi mdi-briefcase-outline"
                                style="margin-right: 8px;"
                                ></v-icon>
                                <small style="color: black !important; font-size: 16px; font-weight: 780;">Empresa: </small> <small style="font-size: 17px; font-weight: 500;">(Engefab)</small>
                            </v-card-title>
                            </v-card>
                        </div>
                        <div v-if="appStore.Modal_Apontamento_Aberto_Body" style="height: 0px;"></div>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </div>
    <Modal />
</template>

<style scoped>
    .card-principal {
        width: 100%;
        max-width: 680px;
        height: auto;
    }
</style>

<script>
import { useAppStore } from "../../stores/app";
import Modal from "./modal.vue"

export default {
    name: "card",
    components: {
        Modal
    },
    props: {
    },
    data() {

    },
    computed: {
        // apontamento: this.apontamento,
        appStore() {
            return useAppStore();
        },
    },
    unmounted() {
        clearInterval(this.interval);
    },
    methods: {

    },
};
</script>