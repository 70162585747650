import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Card = _resolveComponent("Card")
  const _component_Modal = _resolveComponent("Modal")
  const _component_Overlay_events = _resolveComponent("Overlay_events")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card),
    _createVNode(_component_Modal),
    _createVNode(_component_Overlay_events)
  ]))
}