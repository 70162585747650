import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")

  return ($data.cards)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createCommentVNode(" Apontamentos de Produção "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cards, (card) => {
            return (_openBlock(), _createBlock(_component_v_row, null, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(card.description), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}