<template>
    <v-container fluid v-if="cards">
        <!-- Apontamentos de Produção -->
        <v-row v-for="card in cards">
            <div>{{ card.description }}</div>
        </v-row>
    </v-container>
</template>




<script>

import Card from './card.vue';


export default {
    name: "OverlayEvents",
    components: { Card },
    data() {
        return {
        cards: this.appstore.cards

        }
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
};
</script>
<style scoped></style>