<template>
    <div>
        <Card/>
        <Modal/>
        <Overlay_events/>
    </div>
</template>

<script>
import { useAppStore } from './stores/app'
import Card from './app/components/card.vue'
import Modal from './app/components/modal.vue';
import OverlayEvents from './app/components/overlay_events.vue';
export default {
    name: 'App',
    components: {
        Card,
        Modal,
    },
    data() {
        return {
            count: 0
        }
    },
    computed: {
        appStore() {
            return useAppStore();
        },

    },
    methods: {
        // async initial_fetch() {
        //     await this.appStore.initial_fetch()
        // }
    },
    async beforeMount() {
        await this.appStore.initial_fetch()
    },
    async mounted() {
        console.log('loaded')
        if(!this.appStore.group_by) {
            console.log('renderiza o modal para escolha do tipo de dashboard (not implemented)')
        } 
        // await this.appStore.initial_fetch()
        // frappe.realtime.on('apontamento-web', (data) => {
        //     console.log('apontamento_web', data)
        //     this.appStore.update_store_apontamento(data)
        // })
        // frappe.realtime.on('recurso-web', (data) => {
        //     console.log('recurso-web', data)
        //     this.appStore.update_store_recurso(data)
        // })
        // frappe.realtime.on('operador-web', (data) => {
        //     console.log('operador-web', data)
        //     this.appStore.update_store_operador(data)
        // })
        // frappe.realtime.on('ordem_de_producao-web', (data) => {
        //     console.log('ordem_de_producao-web', data)
        //     this.appStore.update_store_ordem_de_producao(data)
        // })
  
    }
}
</script>
<style></style>
