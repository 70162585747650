<template>
    <div>
        <v-dialog v-model="appStore.modal" persistent>
            <v-card>
                <div v-if="!appStore.group_by">
                    <v-select label="Selecione o Dash" 
                    :items="items" 
                    v-model="select"
                    item-title="nome_dashboard"
                    @update:modelValue="set_dash"
                    :item-props="itemProps"
                    item-value="name"
                    >
                    </v-select>
                </div>

                <div v-if="appStore.group_by?.senha_dashboard">
                    <v-text-field
                        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="visible ? 'text' : 'password'"
                        density="compact"
                        placeholder="Digite Sua Senha"
                        prepend-inner-icon="mdi-lock-outline"
                        variant="outlined"
                        @change="setPassword"
                        @click:append-inner="visible = !visible"
                    ></v-text-field>
                    <v-btn
                        @click ="login"
                        class="mb-8"
                        color="blue"
                        size="large"
                        variant="tonal"
                        block
                    >
                        Log In
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped></style>

<script>
import { useAppStore } from "../../stores/app";



export default {
    name: "Modal",
    data: () => {
        return ({
            items: [],
            select: null,
            visible: true,
            password: '',
            modal: null

        })
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
        created() {
           
        },
    async beforeMount() {
        this.items = await this.configsSet()
        console.log('beforeMount',this.items)
        
    },

    methods: {
        itemProps(selection) {
            console.log(selection)
            return selection
        },
        async set_dash(selection) {
            console.log('set_dash',selection)
            const cur_selection = this.items.find(i => i.name == selection)
            console.log("set_dash (not implemented)", cur_selection);
            this.appStore.group_by = cur_selection
            if(!this.appStore.group_by.senha_dashboard) {
                this.appStore.modal = false
                await this.populate_data()
            }
        },
        openModal() {
            this.appStore.modal = true
        },
        closeModal(){
            this.appStore.modal = false
        },
        setPassword({target}){
            console.log("data", target.value)
            this.password = target.value
        },
        async populate_data(){
            await this.appStore.populate_by_config()
        },
        async login(){
            if(this.appStore.group_by.senha_dashboard == this.password){
                console.log("senha correta")
                useAppStore().modal = false
                await this.populate_data()
            }
            
        },
        async configsSet() {
            const all_configs = await frappe.db.get_list("Configuracao Dashboard Web")
            const detailed = await all_configs.map(async({name})=> (
                await frappe.db.get_doc('Configuracao Dashboard Web', name)
            ))
            console.log('all_configs', await detailed)
            this.configs = await Promise.all(detailed);
            return await this.configs;
        },

        
    },


};
</script>
