import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: $options.appStore.modal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($options.appStore.modal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            (!$options.appStore.group_by)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_v_select, {
                    label: "Selecione o Dash",
                    items: _ctx.items,
                    modelValue: _ctx.select,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = $event => ((_ctx.select) = $event)),
                      $options.set_dash
                    ],
                    "item-title": "nome_dashboard",
                    "item-props": $options.itemProps,
                    "item-value": "name"
                  }, null, 8 /* PROPS */, ["items", "modelValue", "onUpdate:modelValue", "item-props"])
                ]))
              : _createCommentVNode("v-if", true),
            ($options.appStore.group_by?.senha_dashboard)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_v_text_field, {
                    "append-inner-icon": _ctx.visible ? 'mdi-eye-off' : 'mdi-eye',
                    type: _ctx.visible ? 'text' : 'password',
                    density: "compact",
                    placeholder: "Digite Sua Senha",
                    "prepend-inner-icon": "mdi-lock-outline",
                    variant: "outlined",
                    onChange: $options.setPassword,
                    "onClick:appendInner": _cache[1] || (_cache[1] = $event => (_ctx.visible = !_ctx.visible))
                  }, null, 8 /* PROPS */, ["append-inner-icon", "type", "onChange"]),
                  _createVNode(_component_v_btn, {
                    onClick: $options.login,
                    class: "mb-8",
                    color: "blue",
                    size: "large",
                    variant: "tonal",
                    block: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Log In ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}