import { defineStore } from "pinia";
declare let frappe: any;

export const useAppStore = defineStore({
    id: "app",
    state: () => ({
        group_by: null,
        cards: null,
        modal: true,
        configs: null,
        login: null,

    }),
    getters: {

    },

    actions: {

        async populate_by_config() {



            if (this.group_by) {
                try {

                    const doctype = this.group_by.tipo_dashboard === "Operadores da Lista" ? "Operador" : "Recurso";
                    console.log(doctype)
                    const field_table = `${doctype}_table`.toLowerCase();
                    const filter_this_data = this.group_by[field_table];


                    const list = await frappe.db.get_list(doctype, {
                        fields: "*",
                        filters: filter_this_data.length ? {
                            status: "Ativo",
                            name: ["in", filter_this_data.map(e => e[doctype.toLowerCase()])],
                        } : {
                            status: "Ativo",
                        },
                    }).then(loaded => {
                        console.log(`${doctype}s carregados:`, loaded);
                        return loaded.map(e => e);
                    });

                    this.cards = list.map(item => ({
                        name: item.name,
                        description: item.descricao || item.nome, // Ajuste conforme a estrutura de dados
                        status: item.status,
                    }));

                    console.log("Cards atualizados com operadores/recursos:", this.cards);

                    const apontamentoFieldTable = `apontamento_table`;
                    const apontamentoFilterData = this.group_by[apontamentoFieldTable];

                    if (apontamentoFilterData) {
                        const apontamentos = await frappe.db.get_list("Apontamentos", {
                            fields: ["name", "descricao", "data", "status"],
                            filters: {
                                status: "Ativo",
                                name: ["in", apontamentoFilterData.map(e => e.apontamento)],
                            },
                        }).then(loaded => {
                            console.log("Apontamentos carregados:", loaded);
                            return loaded.map(e => e);
                        });

                        this.cards = [
                            ...this.cards,
                            ...apontamentos.map(apontamento => ({
                                name: apontamento.name,
                                description: apontamento.descricao,
                                date: apontamento.data,
                                status: apontamento.status,
                            })),
                        ];

                        console.log("Cards atualizados com apontamentos:", this.cards);
                    }
                } catch (error) {
                    console.error("Erro ao carregar dados por configuração:", error);
                }
            }
        },

        async initial_fetch() {
            const all_configs = await frappe.db.get_list("Configuracao Dashboard Web")
            const detailed = await all_configs.map(async ({ name }) => (
                await frappe.db.get_doc('Configuracao Dashboard Web', name)
            ))
            console.log('all_configs', await detailed)
            this.configs = await Promise.all(detailed);
        },
        select_config(config) {
            console.log('select_config (not implemented)', config)
        },
        update_apontamento(apontamento) {
            console.log('update_apontamento (not implemented)', apontamento)
        },
        update_operador(operador) {
            console.log('update_operador (not implemented)', operador)

        },
        update_recurso(recurso) {
            console.log('update_recurso (not implemented)', recurso)

        },

        persist: {
            enabled: true,
        },


    },
});
